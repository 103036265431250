.FlexibilityBox {
  background-image: unset;
  padding-bottom: 0;
}
.FlexiblePrice {
  background-image: linear-gradient(to right, #bcbfc0 33%, rgba(236, 236, 236, 0) 0%);
  background-position: top;
  background-size: 8px 1px;
  background-repeat: repeat-x;
  padding-top: 1rem;
  width: 100%;
}
.FlexibilityFormControl {
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  margin-top: 0;
}

.disabledLabel {
  color: red;
  margin-left: 2.5rem;
  font-size: 14px;
}
