.BookingStickyMenuModal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  background-color: var(--booking-brand-color-2);
  z-index: 4;
  opacity: 0;
  width: 100%;
  transition: opacity 0.1s ease-in;
  visibility: hidden;
  scroll-behavior: smooth;
}

.BookingStickyMenuModal_Active {
  opacity: 1;
  z-index: 21;
  visibility: visible;
  overflow: auto;
}

@media screen and (min-width: 1024px) {
  .BookingStickyMenuModal_Active {
    background-color: rgba(0, 0, 0, 0.7);
  }
  .BookingStickyMenuModal {
    padding: 1rem;
  }
}
