.renderBoxContainer {
  width: 100%;
  margin-bottom: 1rem;
  text-align: left;
}

.renderBox {
  border-radius: 20px !important;
  background-color: #ffffff;
}

.roomBoxContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1.25rem;
  gap: 1rem;
  margin: 0 auto;
  color: var(--booking-brand-color-2);
}
.roomBoxContainer {
  border-top: 1px solid #212121;
}

.roomBoxContainer:first-child {
  border-top: 0;
}

.roomType {
  padding-bottom: 1rem;
  background-image: linear-gradient(to right, #bcbfc0 33%, rgba(236, 236, 236, 0) 0%);
  background-position: bottom;
  background-size: 8px 1px;
  background-repeat: repeat-x;
  h3 {
    font-size: 26px;
  }
}

.numberOfPersons {
  display: flex;
  flex-direction: column;
}

.FormControls {
  display: flex;
  flex-direction: column;
}

.FormControl {
  display: flex;
  gap: 2rem;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 1rem;
}
.FormControl input,
.FormControl label {
  margin: 0 2px 0 0;
  width: 100%;
}

.roomDetails {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.roomPrice {
  text-align: right;
  p {
    font-size: 12px;
  }
  h3 {
    font-weight: bold;
    font-size: 26px;
    span {
      font-size: 16px;
    }
    span:nth-of-type(2) {
      font-size: 26px;
      margin-left: 5px;
    }
  }
}

.selectWrapper {
  position: relative;
  border-radius: 5px;
  border: 1px solid var(--booking-brand-color-2);
}
.selectWrapper select,
.selectWrapper select:focus-visible {
  // outline: 1px solid var(--booking-brand-color-2);
  outline: 1px solid transparent;
}

.selectText {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxMCAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik00LjUgMEM0LjUgMi40ODUyOCAyLjQ4NTI4IDQuNSAwIDQuNVY1LjVDMi4yMTg2IDUuNSA0LjEzMDM5IDQuMTg2MzcgNSAyLjI5NDQ4QzUuODY5NjEgNC4xODYzNyA3Ljc4MTQgNS41IDEwIDUuNVY0LjVDNy41MTQ3MiA0LjUgNS41IDIuNDg1MjggNS41IDBINC41Wk00LjUgMTNDNC41IDEwLjUxNDcgMi40ODUyOCA4LjUgMCA4LjVWNy41QzIuMjE4NiA3LjUgNC4xMzAzOSA4LjgxMzYzIDUgMTAuNzA1NUM1Ljg2OTYxIDguODEzNjMgNy43ODE0IDcuNSAxMCA3LjVWOC41QzcuNTE0NzIgOC41IDUuNSAxMC41MTQ3IDUuNSAxM0g0LjVaIiBmaWxsPSIjNEU1MzU2Ii8+Cjwvc3ZnPgo=');
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 16px;
  border-radius: 5px;
  font-size: 14px;
  width: 150px;
  height: 45px;
  padding-left: 6px;
  padding-top: 8px;
}

.floatingLabel {
  font-size: 14px;
  white-space: nowrap;
  position: absolute;
  pointer-events: none;
  left: 7px;
  top: 12px;
  transition: 0.2s ease all;
}
.floatingLabelMoved {
  top: -1px;
  bottom: 14px;
  left: 5px;
  opacity: 1;
  font-size: 10px;
}

.selectLabel {
  white-space: nowrap;
  position: absolute;
  pointer-events: none;
  top: 4px;
  bottom: 14px;
  left: 7px;
  opacity: 1;
  font-size: 10px;
}

.Hidden {
  visibility: hidden;
}

.SoldOut {
  .roomType {
    h3 {
      color: #bcbfc0;
    }
  }
  .roomLabel {
    visibility: hidden;
  }
  .RoomDescription,
  .selectLabel,
  .selectText {
    color: #bcbfc0;
  }
  .selectText {
    outline: 1px solid #bcbfc0;
    pointer-events: none;
  }
}

@media only screen and (min-width: 1024px) {
  .FormControls {
    flex-direction: row;
    gap: 2rem;
  }
  .numberOfPersons {
    flex-direction: row;
    column-gap: 2rem;
    row-gap: 1rem;
    flex-wrap: wrap;
  }
}
