.ExtraBox {
  border-bottom: unset;
}
.ExtraBoxFormControl {
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  background-image: linear-gradient(to right, #bcbfc0 33%, rgba(236, 236, 236, 0) 0%);
  background-position: bottom;
  background-size: 8px 1px;
  background-repeat: repeat-x;
  padding-bottom: 1rem;
  margin-top: 0;
}

.buffetOptions {
  display: flex;
  flex-direction: column;
  gap: 12px;
  p {
    font-size: 12px;
  }
  .selectText {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxMCAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik00LjUgMEM0LjUgMi40ODUyOCAyLjQ4NTI4IDQuNSAwIDQuNVY1LjVDMi4yMTg2IDUuNSA0LjEzMDM5IDQuMTg2MzcgNSAyLjI5NDQ4QzUuODY5NjEgNC4xODYzNyA3Ljc4MTQgNS41IDEwIDUuNVY0LjVDNy41MTQ3MiA0LjUgNS41IDIuNDg1MjggNS41IDBINC41Wk00LjUgMTNDNC41IDEwLjUxNDcgMi40ODUyOCA4LjUgMCA4LjVWNy41QzIuMjE4NiA3LjUgNC4xMzAzOSA4LjgxMzYzIDUgMTAuNzA1NUM1Ljg2OTYxIDguODEzNjMgNy43ODE0IDcuNSAxMCA3LjVWOC41QzcuNTE0NzIgOC41IDUuNSAxMC41MTQ3IDUuNSAxM0g0LjVaIiBmaWxsPSIjNEU1MzU2Ii8+Cjwvc3ZnPgo=');
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 15px;
    border-radius: 5px;
    font-size: 14px;
    width: 100%;
    height: 45px;
    padding-left: 4px;
  }
}

@media only screen and (min-width: 1024px) {
  .buffetOptions {
    .selectText {
      background-position-x: 98%;
    }
  }
  .selectWrapper {
    width: 50%;
  }
}
