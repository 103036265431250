.cancelBox {
  background-image: unset;
  padding-bottom: 0;
  h2 {
    font-size: 20px;
  }
  .headline {
    background-image: linear-gradient(to right, #bcbfc0 33%, rgba(236, 236, 236, 0) 0%);
    background-position: bottom;
    background-size: 8px 1px;
    background-repeat: repeat-x;
    padding-bottom: 10px;
  }
  .datesContainer {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
    align-items: center;
    border-radius: 0px;
    padding: 1rem 0;
    background-image: linear-gradient(to right, #bcbfc0 33%, rgba(236, 236, 236, 0) 0%);
    background-position: bottom;
    background-size: 8px 1px;
    background-repeat: repeat-x;
    .arrival,
    .departure {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      text-transform: uppercase;
      .arrivalLabel,
      .departureLabel {
        font-size: 10px;
      }
    }
  }
  .cartItems {
    padding: 1rem;
    font-size: 12px;
    background-image: linear-gradient(to right, #bcbfc0 33%, rgba(236, 236, 236, 0) 0%);
    background-position: bottom;
    background-size: 8px 1px;
    background-repeat: repeat-x;
    .cartItem {
      display: flex;
      justify-content: space-between;
    }
    .totalPrice {
      padding-top: 1rem;
      font-weight: bold;
      text-align: right;
    }
  }
  .CancelButton {
    text-align: right;
    padding-top: 1rem;
    button {
      margin-top: 0;
    }
  }
}
.modalButtonTouched {
  color: red;
}

//media min-width 1024px
@media screen and (min-width: 1024px) {
  .CancelBox {
    .DatesContainer {
      justify-content: space-between;
    }
  }
}
