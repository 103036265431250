.BookingStickyMenuStep {
  background-color: var(--booking-brand-color-2);
  z-index: 100;
  width: 100%;
  height: 100%;
  transform: translateY(100vh);
  transition: transform 0.6s ease-in;
  visibility: visible;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
}
.BookingStickyMenuStep_Active {
  visibility: visible;
  transform: translateY(0vh);
}
.BookingStickyMenuStep_NotActive {
  transform: translateY(100vh);
  display: none;
  visibility: hidden;
}

.Container {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #fff;
  width: 95%;
  max-width: 95%;
  margin: auto;
  padding: 1rem 0;
}

.Header {
  color: #fff;
  // border-bottom: 1px solid #fff;
  // border-radius: 0 0 0 20px;
  padding: 10px 0 10px 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  margin: 0 auto;
  position: relative;
  svg {
    cursor: pointer;
  }
  &::after {
    display: block;
    border-bottom-left-radius: 16px;
    border-bottom: 1px solid;
    border-left: 1px solid;
    content: '';
    width: 100%;
    height: 1rem;
    position: absolute;
    left: 0;
    bottom: 0;
    color: #fff;
  }
}

.Content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  gap: 1rem;
  flex-wrap: wrap;
  width: 90%;
  min-height: 20vh;
  border-radius: 20px !important;
  background-color: #ffffff;
  color: black;
}

.Footer {
  background-color: #fff;
  display: flex;
  height: 4rem;
  justify-content: space-between;
  padding: 2px 0.4rem 2px 1rem;
  align-items: center;
  .Complete {
    width: 105px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    background-color: var(--booking-brand-color-1);
    color: var(--booking-brand-color-2);
    border-radius: 100px 0px 60px 100px;
    border: 0;
  }
}

@media screen and (min-width: 1024px) {
  .BookingStickyMenuStep {
    justify-content: center;
    // removed fixed width ONG-4276
    // width: 1300px;
    max-width: 1300px;
    min-height: 100%;
    height: 100%;
    padding: 0 1rem;
  }
  .Content {
    width: 50%;
  }
  .Header {
    width: 100%;
  }
  .Container {
    height: 100%;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    flex-direction: row;
    justify-content: center;
    gap: 3rem;
    // padding: 2rem 0 1rem 0;
  }
  .Footer {
    display: none;
  }
}
