.cartContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  margin: 0 auto;
  color: var(--booking-brand-color-2);
  background-color: #f7e4df;
  border-radius: 20px 20px 0 0;
  text-align: left;
  padding: 1.25rem;

  h2 {
    font-weight: normal;
    line-height: 1.5rem;
  }
  h2.subHeadline {
    font-size: 24px;
  }
  .cartDates {
    display: flex;
    gap: 3rem;
    h5 {
      font-size: 10px;
      text-transform: uppercase;
      font-weight: normal;
      letter-spacing: 1.5px;
    }
    p {
      font-weight: bold;
    }
    //danish dates capitalize only day name
    p::first-letter {
      text-transform: capitalize;
    }
    //english Dates capitalize all words
    p.englishDate {
      text-transform: capitalize;
    }
  }
}
.cartTotal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f7e4df;
  color: var(--booking-brand-color-2);
  border-top: 1px solid var(--booking-brand-color-2);
  border-radius: 0 0 20px 20px !important;
  padding: 1.25rem;
  width: 100%;
  h3 {
    font-weight: bold;
    font-size: 20px;
    span {
      font-size: 16px;
    }
    span:nth-of-type(2) {
      font-size: 26px;
      margin-left: 5px;
    }
  }
}

.emptyCart {
  text-align: center;
  background-image: linear-gradient(to right, black 33%, rgba(234, 255, 255, 0) 0%);
  background-position: top;
  background-size: 8px 1px;
  background-repeat: repeat-x;
  h3 {
    margin-top: 8px;
    font-size: 26px;
  }
}

.paymentWrapper {
  display: flex;
  gap: 10px;
  flex-direction: column;
  padding: 1rem 5px;
  .paymentLabel {
    font-size: 14px;
    color: white;
    transition: color 0.3s ease-in-out;
  }
  .errorLabel {
    color: red;
  }
}

.newsletterContainer,
.paymentContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.5rem;
  .newsletterLabel,
  .paymentLabel {
    margin-top: 5px;
    a {
      transition: text-shadow 0.3s ease-in-out;
      text-decoration: underline;
    }
    a:hover {
      text-shadow: 0 0 0 #fff;
    }
    h3 {
      font-size: 16px;
    }
    div {
      margin-top: 1rem;
      font-size: 14px;
    }
  }
}
