.RoomBoxContent,
.CartContent {
  width: 100%;
  scroll-behavior: smooth;
}

.ChakraSlide {
  width: 0;
  display: block;
}
.ChakraSlideHide {
  display: none;
}

@media screen and (min-width: 1024px) {
  .BookingStickyMenuRoomPickerStep {
    height: fit-content;
    padding: 0 1rem;
  }
  .BookingStickyMenuRoomPickerStep_Loading {
    height: 100%;
    .RoomPickerContainer {
      align-items: center;
    }
  }
  .RoomPickerContainer {
    align-items: flex-start;
    flex-wrap: wrap;
  }
  .RoomBoxContent,
  .CartContent {
    height: 100%;
    // overflow-y: scroll;
    // &::-webkit-scrollbar {
    //   display: none;
    // }
    // & {
    //   -ms-overflow-style: none;
    //   scrollbar-width: none;
    // }
  }
  .RoomBoxContent {
    flex: 2;
  }
  .CartContent {
    flex: 1;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    margin-top: 71.19px;
  }
  .ChakraSlide {
    position: relative !important;
  }
}
