.cartItem {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  opacity: 0.9;
  width: 100%;
  // border-top: 1px dashed #4e5356;
  gap: 0.5rem;
  padding-top: 1rem;
  background-image: linear-gradient(to right, black 33%, rgba(234, 255, 255, 0) 0%);
  background-position: top;
  background-size: 8px 1px;
  background-repeat: repeat-x;
}

.cartSubtitle,
.cartPrice {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cartPrice {
  font-weight: bold;
  h3 {
    font-weight: bold;
    font-size: 12px;
    span {
      font-size: 16px;
    }
    span:nth-of-type(2),
    .soldOut {
      font-size: 26px;
      margin-left: 5px;
    }
  }
  p {
    text-align: left;
  }
}
.cartSubtitle p {
  text-align: left;
}

.removeIcon {
  font-size: 10px;
  border: 1px solid black;
  border-radius: 50%;
  cursor: pointer;
  text-align: center;
}

@media only screen and (min-width: 1024px) {
  .cartItemContainer {
    width: 80%;
  }
}
