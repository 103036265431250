.BookingStickyMenuDatePickerStep {
  width: 100%;
  margin: 0 auto;
  background-color: transparent !important;
}
.DatePickerWrapper {
  color: red;
}
.DatePickerContainer {
  width: 95%;
  margin: auto;
  padding: 0rem 0 1rem 0;
  position: relative;
  // height: 100%;
  overflow: hidden;
  //style second div which is datepicker
  & > div:nth-of-type(2) {
    height: auto;
    max-height: 70vh;
    overflow: auto;
  }
}

.DatePickerHeader {
  top: 0;
  z-index: 5;
  background-color: var(--booking-brand-color-2);
}

.DatePickerWeekDays {
  display: flex;
  justify-content: center;
  height: 3rem;
  margin: 0 auto;
  margin-top: 5px;
  align-items: center;
  gap: 22px;
  font-size: 12px;
  top: 10vh;
  border-radius: 20px 20px 0 0;
  border: 1px solid #aeaeae;
  background-color: #fff;
  z-index: 5;
}

.Footer {
  margin-top: -2rem;
  position: relative;
  z-index: 3;
  border-top: 1px solid #aeaeae;
  background-color: #fff;
  border-radius: 0 0 20px 20px !important;
}

.Arrival,
.Departure {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  text-transform: uppercase;
  .ArrivalLabel,
  .DepartureLabel {
    font-size: 10px;
  }
}

@media screen and (min-width: 1024px) {
  .DatePickerContainer {
    width: 42rem;
    padding: 0;
    position: absolute;
    left: 50%;
    height: fit-content;
    transform: translateX(-50%);
    bottom: 120px;
    & > div:first-of-type {
      height: fit-content;
      margin-top: 0;
    }
  }
  .DatePickerWeekDays {
    display: none;
  }
  .DatePickerHeader {
    display: none !important;
  }
  .BookingStickyMenuDatePickerStep {
    height: 100%;
  }
}
