.Container {
  align-items: flex-start;
  gap: 1rem;
}
.reservationInfo {
  p {
    font-size: 12px;
    padding-left: 5px;
  }
}

@media screen and (min-width: 1024px) {
  .BookingStickyMenuRoomChangeStep {
    height: fit-content;
    justify-content: flex-start !important;
  }
  .Container {
    align-items: center;
    flex-direction: column;
  }
}
