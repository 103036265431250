.paymentButton {
  height: 45px;
  padding-left: 2.2rem;
  color: var(--booking-brand-color-2);
  background-color: var(--booking-brand-color-1);
  position: relative;
  overflow: hidden;
  border-radius: 0px 50px 50px 50px;
  transition: background-color 0.4s ease-in-out;
  &:disabled {
    background-color: var(--dimmed-2-booking-brand-color-1)!important;
    cursor: not-allowed;
    &:hover {
      .text,
      .revertText {
        transform: none;
      }
    }
  }
  &:hover {
    background-color: var(--dimmed-booking-brand-color-1);
    .text {
      transform: translateY(-110%);
      transition: 0.25s linear;
    }
    .revertText {
      transform: translateY(-100%);
      transition: 0.25s linear;
    }
  }
  .text {
    transition: 0.25s linear;
    line-height: 16px;
    font-size: 14px;
  }
  .revertText {
    line-height: 1;
  }
  .text,
  .revertText {
    z-index: 1;
    color: black;
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: center;
  }
  .revertText {
    position: absolute;
    width: 100%;
    transition: 0.25s ease-in-out;
    line-height: 1;
    font-size: 14px;
  }
}
