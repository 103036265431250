.Container {
  display: flex;
  color: #fff;
  width: 95%;
  height: 4rem;
  justify-content: space-between;
  padding: 1rem;
  margin: 0 auto;
  align-items: center;
  border-radius: 0px;
  border-bottom: 2px solid rgb(63, 63, 63);
  cursor: pointer;
  button {
    color: #fff;
    border-radius: 100px 100px 0px 100px;
    line-height: 0;
    padding: 20px;
    border: 1px solid #fff;
    margin-top: 0;
  }
  .Arrival,
  .Departure {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    text-transform: uppercase;
    .ArrivalLabel,
    .DepartureLabel {
      font-size: 10px;
      letter-spacing: 1.5px;
    }
  }
}
.changeContainer {
  color: #000;
  button {
    border: 1px solid #000;
    color: #000;
    background-color: #fff;
  }
}

@media (max-width: 1023px) {
  .Arrival,
  .Departure {
    max-width: 25%;
  }
}

@media (min-width: 1024px) {
  .Container {
    justify-content: center;
    gap: 2rem;
    width: 100%;
  }
}
