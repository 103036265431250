.selectWrapper {
  position: relative;
  border-radius: 5px;
}
.selectText {
  width: 100%;
  outline: 1px solid #e9e9e4;
  background-position-x: 98%;
}
.companyName {
  user-select: none;
  cursor: not-allowed;
  input {
    background-color: #d9d9d9;
    pointer-events: none;
  }
  label {
    color: #6c7073;
    pointer-events: none;
  }
}
@media only screen and (min-width: 1700px) {
  .selectText {
    background-position-x: 99%;
  }
}
