.CompanyContainer {
  background-color: #e9e9e4;
  border-radius: 20px;
}
.CompanyRatesBox {
  border-bottom: unset;
  background-image: unset;
  padding-bottom: 0;
  .CompanyAdvantages {
    margin-left: 20px;
    padding: 1rem 0;
    line-height: 2rem;
  }
}

// .CompanyList {
//   margin-left: 10px;
//   list-style: none;
//   li::marker {
//     content: '\2713';
//   }
// }
