.brandButton {
  height: 45px;
  line-height: 1;
  background-color: var(--booking-brand-color-2);
  border: 1px solid var(--booking-brand-color-2);
  font-size: 14px;
  margin-top: 10px;
  min-width: fit-content;
  border-radius: 0px 50px 50px 50px;
  padding: 14px;
  color: var(--booking-brand-color-1);
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  white-space: nowrap;
}
@media only screen and (min-width: 1024px) {
  .brandButton {
    margin-top: 0;
  }
}
