.Container {
  margin-top: 0;
  justify-content: flex-start;
  align-items: flex-start;
}
.LoadingContainer {
  margin-top: 0;
  align-items: center;
  justify-content: center;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.5em;
  }
  h3 {
    font-size: 1.17em;
  }
  h4 {
    font-size: 1em;
  }
  h5 {
    font-size: 0.83em;
  }
  h6 {
    font-size: 0.67em;
  }
}

.customFooter {
  background-color: var(--booking-brand-color-2);
  padding-left: 1rem;
  padding-right: 1rem;
  button.Complete {
    width: 100%;
  }
}

//min-width: 1024px
@media (min-width: 1024px) {
  .contentWrapper {
    button.Complete {
      width: 15rem;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      background-color: var(--booking-brand-color-1);
      color: var(--booking-brand-color-2);
      border-radius: 16px 100px 100px 100px;
      border: 0;
    }
  }
}
