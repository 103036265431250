.Container {
  display: flex;
  align-items: center;
  position: fixed;
  justify-content: space-between;
  padding: 0 1rem;
  bottom: 0px;
  width: 100%;
  height: 55px;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 11;
}

.ContainerHidden {
  visibility: hidden;
}

.MenuToggle,
.CartToggler,
.DatePickerToggler {
  background-color: var(--booking-brand-color-2) !important;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.DatePickerToggler {
  height: 100% !important;
  text-align: left;
  margin: 0 1rem;
  border-radius: 25px 25px 0px 25px !important;
  color: white !important;
  &:hover {
    span {
      animation: none !important;
      -webkit-animation: none !important;
    }
  }
}

.MenuToggle,
.CartToggler {
  width: 55px;
  height: 55px;
  border-radius: 50% !important;
  background-color: var(--booking-brand-color-2) !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 7.5px;
  text-transform: uppercase;
  cursor: pointer;
}

.MenuToggle span {
  width: 23px;
  height: 1px;
  background-color: #fff;
  margin-bottom: 5px;
}

@media only screen and (max-width: 1023px) {
  .cartVisible {
    z-index: 21;
    a {
      visibility: visible;
    }
  }
}

//media min-width: 1024px
@media only screen and (min-width: 1024px) {
  .Container {
    display: none;
  }
}

.CartToggler {
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    right: 30%;
    background-color: var(--booking-brand-color-1);
    border-radius: 50%;
    width: 9px;
    height: 9px;
  }
}

.SearchIconContainer {
  display: flex;
  position: relative;
  right: -25%;
  align-items: center;
  justify-content: center;
  background-color: var(--booking-brand-color-1);
  min-width: 45px;
  height: 45px;
  color: #2f2f2f;
  border-radius: 25px 25px 0px 25px;
  p {
    display: none;
  }
}

//Desktop menu styles
.ContainerDesktop.Container {
  max-width: fit-content;
  z-index: 1001;
  bottom: 3%;
  height: 65px;
  display: flex;

  .DatePickerToggler {
    display: flex;
    justify-content: space-between;
    gap: 2.5rem;
    width: auto;
    border-radius: 33px 33px 0px 33px !important;
    .ArrivalDesktop,
    .DepartureDesktop {
      font-weight: normal;
      .DatesLabel {
        font-size: 10px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
      }
      .DatesFormatted {
        font-size: 14px;
        margin-top: 3px;
      }
    }
    transition: width 0.5s ease-out;
  }
  .DatePickerToggler.DatePickerTogglerOpen {
    width: 672px;
  }
  .SearchIconContainer {
    width: 10rem;
    padding: 1rem;
    right: -8%;
    height: 55px;
    transition: background-color 0.3s ease;
    border-radius: 28px 28px 0px 28px;
    p {
      display: block;
      font-size: 14px;
    }
    section {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;
    }
    &:hover {
      background-color: var(--dimmed-booking-brand-color-1);
    }
  }
  .CartToggler {
    position: relative;
  }
  .DatesContainerDesktop {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    margin-left: .5rem;

    img {
      width: 30px;
      height: 30px;
      object-fit: cover;
      object-position: center;
    }

    span {
      width: 30px;
      height: 30px;
    }
  }

  .bookingStickyMenuFirstText {
    font-size: 10px;
    line-height: 1.4;
    letter-spacing: 1.5px;
    font-weight: normal;
    text-transform: uppercase;
    color: #ffffff;
  }

  .bookingStickyMenuSecondText {
    margin-top: 2px;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: -0.43px;
    color: #ffffff;
    font-weight: normal;
  }
}
